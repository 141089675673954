import type { Components } from "@mui/material/styles";

export const MuiTooltip: Components["MuiTooltip"] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      padding: "0.25rem 1rem",
      borderRadius: 0,
      fontWeight: 500,
    },
  },
};
