import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import Info from "@mui/icons-material/Info";
import Lock from "@mui/icons-material/Lock";
import Warning from "@mui/icons-material/Warning";
import { styled } from "@mui/material";
import type {
  OptionsObject,
  SnackbarKey,
  SnackbarOrigin,
  SnackbarProviderProps,
} from "notistack";
import {
  MaterialDesignContent,
  SnackbarProvider as NotistackProvider,
} from "notistack";
import { useCallback, useRef } from "react";
import { FormattedMessage } from "react-intl";

import * as sx from "./index.sx";

const SNACKBAR_ICONS = {
  error: Error,
  info: Info,
  success: CheckCircle,
  warning: Warning,
} as const;
type Variant = keyof typeof SNACKBAR_ICONS;
export const VARIANTS = Object.keys(SNACKBAR_ICONS) as Variant[];

const iconVariant = Object.fromEntries(
  Object.entries(SNACKBAR_ICONS).map(([variant, Icon]) => [
    variant,
    /**
     * Add `data-testid` to snackbar icons for testing
     */
    <Icon
      key={variant}
      data-testid={`${variant} snackbar icon`}
      sx={sx.icon}
    />,
  ])
);

const snackbarOrigin: SnackbarOrigin = {
  horizontal: "right",
  vertical: "bottom",
};

const StyledContent = styled(MaterialDesignContent)(({ theme }) => ({
  backgroundColor: `${theme.palette.grey[900]} !important`,
}));
const Components: SnackbarProviderProps["Components"] = {
  default: StyledContent,
  error: StyledContent,
  info: StyledContent,
  success: StyledContent,
  warning: StyledContent,
};

export const SnackbarProvider = ({
  autoHideDuration = 3000,
  children,
}: {
  autoHideDuration?: number;
  children: React.ReactNode;
}) => {
  const notistackRef = useRef<NotistackProvider>(null);

  const action = useCallback(
    (key: SnackbarKey) => (
      <sx.CloseButton
        data-testid="close toast"
        size="small"
        onClick={() => {
          notistackRef.current!.closeSnackbar(key);
        }}
      >
        <sx.CloseIcon fontSize="small" />
      </sx.CloseButton>
    ),
    [notistackRef]
  );

  return (
    <NotistackProvider
      anchorOrigin={snackbarOrigin}
      autoHideDuration={autoHideDuration}
      Components={Components}
      iconVariant={iconVariant}
      maxSnack={1}
      ref={notistackRef}
      action={action}
    >
      {children}
    </NotistackProvider>
  );
};

export const getPermissionSnackbarMessage = (message?: React.ReactNode) => (
  <>
    <Lock fontSize="small" sx={sx.icon} />
    {message || <FormattedMessage id="error.access" />}
  </>
);

export const getPermissionSnackbarOptions = (
  options?: Partial<OptionsObject>
): OptionsObject => ({
  preventDuplicate: true,
  variant: "default",
  ...options,
});
