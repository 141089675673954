import type {
  Color,
  CommonColors,
  PaletteColor as MuiPaletteColor,
  PaletteOptions as MuiPaletteOptions,
  TypeText as MuiTypeText,
  PaletteColorOptions,
} from "@mui/material";

const common = {
  black: "#000",
  white: "#FFF",
} as const satisfies CommonColors;

const text = {
  primary: "rgba(0, 0, 0, 0.84)",
  secondary: "rgba(0, 0, 0, 0.60)",
  disabled: "rgba(0, 0, 0, 0.26)",
  hint: "rgba(0, 0, 0, 0.26)",
} as const satisfies MuiTypeText;

const grey = {
  50: "#FAFAFA",
  100: "#F5F5F5",
  200: "#EEEEEE",
  300: "#D4D4D4",
  400: "#BDBDBD",
  500: "#9E9E9E",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#282828",
} as const satisfies Partial<Color>;

const primary = {
  light: "#FFEEDB",
  main: "#FFB25B",
  dark: "#E28D30",
  contrastText: text.primary,
} as const satisfies MuiPaletteColor;

const secondary = {
  light: primary.light,
  main: primary.light,
  dark: primary.main,
  contrastText: text.primary,
} as const satisfies MuiPaletteColor;

const dataViz = {
  dataViz01: {
    light: "#D0BFC7",
    main: "#8A6075",
    dark: "#604351",
    contrastText: "#FFF",
    background: "#FFF2F8",
  },

  dataViz02: {
    light: "#EED5EE",
    main: "#C79FC7",
    dark: "#956A95",
    contrastText: "#372C2C",
    background: "#FFF2FF",
  },

  dataViz03: {
    light: "#C3B8D6",
    main: "#8B6F9E",
    dark: "#49376A",
    contrastText: "#040101",
    background: "#F7F2FF",
  },

  dataViz04: {
    light: "#CDD3E4",
    main: "#8492BD",
    dark: "#5C6583",
    contrastText: "#1E1818",
    background: "#F2F6FF",
  },

  dataViz05: {
    light: "#DFEDFD",
    main: "#AFD4FA",
    dark: "#7A93AE",
    contrastText: "#504646",
    background: "#F2F8FF",
  },

  dataViz06: {
    light: "#C2D4D4",
    main: "#689494",
    dark: "#486767",
    contrastText: "#FFF",
    background: "#F2FFFF",
  },

  dataViz07: {
    light: "#C8DCD4",
    main: "#76A895",
    dark: "#527568",
    contrastText: "#1E1E1E",
    background: "#F2FFFA",
  },

  dataViz08: {
    light: "#D9F5DF",
    main: "#A1E6AF",
    dark: "#70A07A",
    contrastText: "#504646",
    background: "#F2FFF5",
  },

  dataViz09: {
    light: "#D6E0CB",
    main: "#9AB37D",
    dark: "#6E7863",
    contrastText: "#372C2C",
    background: "#F9FFF2",
  },

  dataViz10: {
    light: "#F0F0CD",
    main: "#DBDA84",
    dark: "#98985C",
    contrastText: "#504646",
    background: "#FFFFF2",
  },

  dataViz11: {
    light: "#F9EDD7",
    main: "#F0D29C",
    dark: "#A7926C",
    contrastText: "#504646",
    background: "#FFFBF2",
  },

  dataViz12: {
    light: "#ECDBDD",
    main: "#D1A7AA",
    dark: "#917476",
    contrastText: "#373030",
    background: "#FFF2F5",
  },

  dataViz13: {
    light: "#E4E4E4",
    main: grey[400],
    dark: "#606060",
    contrastText: "#000000",
    background: grey[200],
  },
} as const satisfies Record<
  `dataViz${string}`,
  MuiPaletteColor | PaletteColorOptions
>;

type DataViz = typeof dataViz;

const customPalette = {
  candidate: {
    light: "#FFE9F1",
    main: "#FF5891",
    dark: "#9F0A60",
    contrastText: "#4D002C",
    background: "#FFF2F7",
  },

  card: {
    light: "rgba(20, 20, 20, 0.25)",
    main: "#C4C4C4",
    dark: "rgba(200, 200, 200, 0.25)",
    contrastText: "#444",
  },

  chartGoal: {
    main: "#C2D4D4",
  },

  chartValue: dataViz.dataViz06,

  data: {
    light: "#D4F5E5",
    main: "#6CBE9F",
    dark: "#3C886B",
    contrastText: "#004D2F",
    background: "#F2FFF9",
  },

  design: {
    light: "#DBF1FA",
    main: "#009CDE",
    dark: "#006793",
    contrastText: "#00364D",
    background: "#F2FBFF",
  },

  ingredient: {
    light: grey[200],
    main: grey[500]!,
    dark: grey[700],
    contrastText: grey[900],
  },

  material: {
    light: "#D6E0CB",
    main: "#9AB37D",
    dark: "#6E7863",
    contrastText: "#FFF",
  },

  measurement: {
    light: "#F0F0CD",
    main: "#DBDA84",
    dark: "#98985C",
    contrastText: "#000",
  },

  plot: {
    main: "#DEE6E3",
  },

  predictor: {
    light: "#EAE1FB",
    main: "#B594F0",
    dark: "#9C76E0",
    contrastText: "#1B004D",
    background: "#F7F2FF",
  },

  process: dataViz.dataViz06,
} as const satisfies Record<string, MuiPaletteColor | PaletteColorOptions>;

type CustomColors = typeof customPalette;

interface CustomPalette extends DataViz, CustomColors {
  "search-space": typeof customPalette.design;
}

export type CustomPaletteKey = keyof CustomPalette;

declare module "@mui/material" {
  interface PaletteColor {
    background?: string;
  }
  interface SimplePaletteColorOptions {
    background?: string;
  }

  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  }
}

export const palette = {
  /**
   * Standard Colors
   */
  primary,
  secondary,
  text,
  background: {
    paper: grey[50],
    default: grey[100],
  },
  common,
  divider: grey[900],
  grey,

  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
  },

  /**
   * Statuses
   */
  error: {
    light: "#FFBDBD",
    main: "#F92222",
    dark: "#C20505",
    contrastText: "#FFF",
    background: "#FFF2F2",
  },
  warning: {
    light: "#FFF7BC",
    main: "#EFD615",
    dark: "#B6A30F",
    contrastText: text.primary,
    background: "#FFFCE6",
  },
  info: {
    light: "#9ED7EF",
    main: "#009CDE",
    dark: "#0080B6",
    contrastText: "#FFF",
  },
  success: {
    background: "#F2FFE5",
    light: "#D5F4B8",
    main: "#73C742",
    dark: "#4D9C1E",
    contrastText: text.primary,
  },

  /**
   * Custom colors
   */
  ...customPalette,
  "search-space": customPalette.design,

  /**
   * Data Visualization colors
   */
  ...dataViz,
} as const satisfies MuiPaletteOptions;
